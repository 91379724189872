import React, { useEffect } from "react";

const PracticeTaskQA = ({ assignTaskQA }) => {
  let pwd_crack_q = "Please enter your password";
  let question1 = "Which year did you graduate college?";
  let question2 = "Which city where you born?";
  let question3 = "What is an indoor activity do you like?";
  let question4 = "What is an outdoor activity you like?";
  let question5 = "What is your favourite hobby?";
  let question6 = "What is the first company you worked for after college?";
  let question7 =
    "What is the name of the company you first did an internship for?";
  let question8 = "What is your mother's maiden name?";
  let question9 = "What is your father's middle name?";
  let question10 = "In what city did you meet your spouse/partner?";
  let question11 = "What is the oldest sibling's first name?";
  let question12 = "What is the first foreign country you visited?";
  let question13 = "What is your favourite book?";
  let question14 = "What is the name of your favourite childhood friend?";

  useEffect(() => {
    assignTaskQA(taskQAEachRound[1]);
  }, [1]);

  const taskQAEachRound = {
    1: {
      test: {
        pwd_reset_qa: {
          alexander_harris: {
            questions: [question2, question3, question1],
            answers: ["Atlanta", "Cooking", "2018"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Easy"],
          },
        },
        pwd_crack_qa: {
          mason_lewis: {
            question: [pwd_crack_q],
            password: ["Batman007"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Easy"],
          },
        },
      },
    },
  };
  return <></>;
};

export default PracticeTaskQA;
