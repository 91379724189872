import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
//import { ToastContainer, toast } from "react-toastify";
import "./Home.css";
import LinuxFolderStructure from "./LinuxFolderStructure";
import TaskQA from "./TaskQA";
import LinuxFolderStructureChoice from "./LinuxFolderStructureChoice";
import TaskQAChoice from "./TaskQAChoice";
import LinuxFolderStructureNoChoice from "./LinuxFolderStructureNoChoice";
import TaskQANoChoice from "./TaskQANoChoice";
import SystemVulnerabilities from "./SystemVulnerabilities";

const Round = () => {
  const apiUrl = process.env.REACT_APP_API_URL || "https://mipha.cs.utep.edu";
  const navigate = useNavigate();
  const location = useLocation();
  const rNumber = location.state.roundNumber;
  const roundNumberAssignedSet = location.state.roundNumberAssignedSet;
  const conditionCounterSet = location.state.conditionCounterSet;
  const totalPoints = location.state.totalPoints;
  const colorConditionChosen = location.state.colorConditionChosen;
  const difficultyConditionChosen = location.state.difficultyConditionChosen;
  console.log(colorConditionChosen);
  console.log(difficultyConditionChosen);
  console.log(roundNumberAssignedSet);
  console.log(roundNumberAssignedSet.length);
  console.log(conditionCounterSet);

  //console.log(location);
  //console.log(rNumber);
  console.log("round points " + totalPoints);
  const [cookies, removeCookie] = useCookies([]);
  const [username, setUsername] = useState("");

  useEffect(() => {
    const handlePopState = () => {
      window.history.go(1);
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    const verifyCookie = async () => {
      /* if (!cookies.token) {
        navigate("/login");
      } */
      const { data } = await axios.post(
        `${apiUrl}`,
        {},
        { withCredentials: true }
      );
      const { status, user } = data;
      setUsername(user);
      return status
        ? console.log(`Hello ${user}`)
        : (removeCookie("token"), navigate("/login"));
    };
    verifyCookie();
  }, [cookies, navigate, removeCookie]);

  const Logout = () => {
    removeCookie("token");
    navigate("/signup");
  };

  const Game1 = () => {
    //console.log("This worked second game worked first");

    let newRoundNumberAssignedSet = roundNumberAssignedSet;
    newRoundNumberAssignedSet.splice(roundNumberIndex, 1);
    //console.log("************", newRoundNumberAssignedSet);
    navigate("/game1", {
      state: {
        roundNumber,
        lFolderStructure,
        taskQuestionSolution,
        systemVulnerabilitiesList,
        totalPoints,
        colorConditionChosen,
        difficultyConditionChosen,
        newRoundNumberAssignedSet,
        conditionCounterSet,
      },
    });
  };

  const randomNumberGeneratorForRoundAssignment = () => {
    let num = Math.floor(Math.random() * roundNumberAssignedSet.length);
    console.log("rnd num generator", num);
    return num;
  };
  /*

  const [roundNumber1, setRoundNumber1] = useState(
    randomNumberGeneratorForRoundAssignment()
  );
  console.log(roundNumber1);
  console.log(roundNumberAssignedSet[roundNumber1]);

  */

  const [roundNumberAssignedSetCurrent, setRoundNumberAssignedSetCurrent] =
    useState(roundNumberAssignedSet);
  const [roundNumberIndex, setRoundNumberIndex] = useState(
    randomNumberGeneratorForRoundAssignment()
  );
  const [roundNumber, setRoundNumber] = useState(rNumber);
  const [roundNumberAssigned, setRoundNumberAssigned] = useState(
    roundNumberAssignedSet[roundNumberIndex]
  );
  const [lFolderStructure, setLFolderStructure] = useState({});
  const [taskQuestionSolution, setTaskQuestionSolution] = useState({});
  const [systemVulnerabilitiesList, setSystemVulnerabilitiesList] = useState(
    {}
  );

  const removeRoundNumber = (roundNumberToRemove) => {
    setRoundNumberAssignedSetCurrent((prevRoundNumber) =>
      prevRoundNumber.filter(
        (roundNumber) => roundNumber !== roundNumberToRemove
      )
    );
  };

  /*
  const roundGame = () => {
    Game1();
  };
  */

  const handleFolderData = (dataFromLinuxFolderStructure) => {
    setLFolderStructure(dataFromLinuxFolderStructure);
  };

  const handleTaskData = (dataFromTaskQA) => {
    setTaskQuestionSolution(dataFromTaskQA);
  };

  const handleVulnerabilitiesData = (dataFromSystemVulnerabilities) => {
    setSystemVulnerabilitiesList(dataFromSystemVulnerabilities);
  };

  return (
    <>
      <div className="home_page">
        <h4>
          {" "}
          Round <span>{roundNumber}</span>
        </h4>
        {difficultyConditionChosen === "Choice" && (
          <LinuxFolderStructureChoice
            roundNum={roundNumberAssigned}
            autoAssignLFS={handleFolderData}
          />
        )}
        {difficultyConditionChosen === "Choice" && (
          <TaskQAChoice
            roundNum={roundNumberAssigned}
            assignTaskQA={handleTaskData}
          />
        )}
        {difficultyConditionChosen === "No Choice" && (
          <LinuxFolderStructureNoChoice
            roundNum={roundNumberAssigned}
            autoAssignLFS={handleFolderData}
          />
        )}
        {difficultyConditionChosen === "No Choice" && (
          <TaskQANoChoice
            roundNum={roundNumberAssigned}
            assignTaskQA={handleTaskData}
          />
        )}

        <SystemVulnerabilities
          roundNum={roundNumberAssigned}
          assignVulnerabilities={handleVulnerabilitiesData}
        />
        <button onClick={Game1}>Start Game</button>
      </div>
    </>
  );
};

export default Round;

/*
  const handleMyClick = (count) => {
    console.log("Handle Click event Fired from Parent", count);
  };

  const roundNumberAdd = () => {
    console.log(roundNumber);
    let x = roundNumber + 1;
    setRoundNumber(x);
    console.log(roundNumber);
  };

  const assignLinuxFolderStructure = (lFolder) => {
    setLFolderStructure(lFolder);
  };

  const testChecker = () => {
    console.log("testChecker");
    console.log(roundNumber);
    console.log(lFolderStructure);
  };

  const applyGameFolders = () => {
    console.log("apply game worked first");
    <LinuxFolderStructure
      roundNumber={roundNumber}
      autoAssignLFS={handleFolderData}
    />;
  };

  


        <button onClick={roundNumberAdd}>Add Round Number</button>
        <button onClick={testChecker}>Test</button>
*/
