import React, { useEffect } from "react";

const TaskQA = ({ roundNum, assignTaskQA }) => {
  let pwd_crack_q = "Please enter your password";
  let question1 = "Which year did you graduate college?";
  let question2 = "Which city where you born?";
  let question3 = "What is an indoor activity do you like?";
  let question4 = "What is an outdoor activity you like?";
  let question5 = "What is your favourite hobby?";
  let question6 = "What is the first company you worked for after college?";
  let question7 =
    "What is the name of the company you first did an internship for?";
  let question8 = "What is your mother's maiden name?";
  let question9 = "What is your father's middle name?";
  let question10 = "In what city did you meet your spouse/partner?";
  let question11 = "What is the oldest sibling's first name?";
  let question12 = "What is the first foreign country you visited?";
  let question13 = "What is your favourite book?";
  let question14 = "What is the name of your favourite childhood friend?";

  useEffect(() => {
    assignTaskQA(taskQAEachRound[roundNum]);
  }, [roundNum]);

  const taskQAEachRound = {
    1: {
      System1: {
        pwd_reset_qa: {
          alexander_harris: {
            questions: [question2, question3, question1],
            answers: ["Atlanta", "Cooking", "2018"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Easy"],
          },
        },
      },
      System2: {
        pwd_reset_qa: {
          derek_simmons: {
            questions: [question10, question7, question2],
            answers: ["Boston", "Dynamic Enterprise", "Philadelphia"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Easy"],
          },
        },
      },
      System3: {
        pwd_reset_qa: {
          liam_smith_1: {
            questions: [question5, question2, question1],
            answers: ["Hiking", "Denver", "2019"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Easy"],
          },
        },
      },
      System4: {
        pwd_reset_qa: {
          mia_wallace: {
            questions: [question2, question4, question1],
            answers: ["Gainesville", "Kayaking", "2017"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Easy"],
          },
        },
      },
      System5: {
        pwd_reset_qa: {
          sophia_turner: {
            questions: [question6, question5, question1],
            answers: ["BrandBoost Media", "Blogging", "2018"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Easy"],
          },
        },
      },
      System6: {
        pwd_reset_qa: {
          mia_thompson: {
            questions: [question7, question2, question4],
            answers: ["CodeCrafters", "Cambridge", "Rock Climbing"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Easy"],
          },
        },
      },
    },
    //Round 2 No Choice Task Reset
    2: {
      System1: {
        pwd_reset_qa: {
          heather_morris: {
            questions: [question10, question9, question12],
            answers: ["Portland", "Lobb", "England"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Hard"],
          },
        },
      },
      System2: {
        pwd_reset_qa: {
          laura_thompson: {
            questions: [question12, question8, question2],
            answers: ["UK", "Liz", "Dallas"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Hard"],
          },
        },
      },
      System3: {
        pwd_reset_qa: {
          olivia_peterson: {
            questions: [question7, question9, question13],
            answers: ["Creative Interiors", "Randy", "Portraits"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Hard"],
          },
        },
      },
      System4: {
        pwd_reset_qa: {
          ethan_parker: {
            questions: [question10, question7, question13],
            answers: ["Denver", "Google", "Good to Great"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Hard"],
          },
        },
      },
      System5: {
        pwd_reset_qa: {
          olivia_kim: {
            questions: [question2, question14, question11],
            answers: ["Boston", "Henry", "Timon"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Hard"],
          },
        },
      },
      System6: {
        pwd_reset_qa: {
          derek_johnson: {
            questions: [question2, question12, question14],
            answers: ["Berkeley", "Greece", "Johnny"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Hard"],
          },
        },
      },
    },
    //Round 3 Choice
    3: {
      System1: {
        pwd_crack_qa: {
          james_hall: {
            question: [pwd_crack_q],
            password: ["ABC123"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Easy"],
          },
        },
      },
      System2: {
        pwd_crack_qa: {
          isabella_clark: {
            question: [pwd_crack_q],
            password: ["123456"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Easy"],
          },
        },
      },
      System3: {
        pwd_crack_qa: {
          evelyn_nelson: {
            question: [pwd_crack_q],
            password: ["Sunshine402"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Easy"],
          },
        },
      },
      System4: {
        pwd_crack_qa: {
          leo_smith: {
            question: [pwd_crack_q],
            password: ["maryjane"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Easy"],
          },
        },
      },
      System5: {
        pwd_crack_qa: {
          abigail_ross: {
            question: [pwd_crack_q],
            password: ["123wasd"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Easy"],
          },
        },
      },
      System6: {
        pwd_crack_qa: {
          casey_watson: {
            question: [pwd_crack_q],
            password: ["spitfire"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Easy"],
          },
        },
      },
    },
    //Round 4 Choice Task
    4: {
      System1: {
        pwd_crack_qa: {
          lily_gonzalez: {
            question: [pwd_crack_q],
            password: ["Dragon123"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Hard"],
            wordlist: ["rockyou.txt"],
          },
        },
      },
      System2: {
        pwd_crack_qa: {
          grace_ramirez: {
            question: [pwd_crack_q],
            password: ["Summer789"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Hard"],
            wordlist: ["sqlmap.txt"],
          },
        },
      },
      System3: {
        pwd_crack_qa: {
          gabriel_parker: {
            question: [pwd_crack_q],
            password: ["Baseball16"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Hard"],
            wordlist: ["hashkiller.txt"],
          },
        },
      },
      System4: {
        pwd_crack_qa: {
          caleb_hughes: {
            question: [pwd_crack_q],
            password: ["Mickey321"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Hard"],
            wordlist: ["hashkiller.txt"],
          },
        },
      },
      System5: {
        pwd_crack_qa: {
          aria_rossi: {
            question: [pwd_crack_q],
            password: ["Jester420420"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Hard"],
            wordlist: ["crackstation.txt"],
          },
        },
      },
      System6: {
        pwd_crack_qa: {
          layla_bell: {
            question: [pwd_crack_q],
            password: ["123xyz456"],
            attemptsLeft: [3],
            attackSuccess: [false],
            difficulty: ["Hard"],
            wordlist: ["rockyou.txt"],
          },
        },
      },
    },
  };
  return <></>;
};

export default TaskQA;
